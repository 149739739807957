import { configureStore } from "@reduxjs/toolkit";
import collapseSlice from "./Slices/subMenuCollapse.slice";
import chosenUserSlice from "./Slices/chosenUser.slice";
import snackbarSlice from "./Slices/snackbar.slice";
import pagesSlice from "./Slices/pages.slice";

export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
    chosenUser: chosenUserSlice,
    pages: pagesSlice,
    collapse: collapseSlice,
  },
});
