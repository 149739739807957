import AuthAxios from "../Services/AuthAxios";

const setAxiosToken = (token = null) => {
  if (token) {
    AuthAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    AuthAxios.defaults.headers.common["Authorization"] = null;
  }
};

export { setAxiosToken };
