import AuthAxios from "./AuthAxios";

class AuthServices {
  login = (data) => AuthAxios.post(`admin/login`, data);
  getById = (id) => AuthAxios.get(`admin/${id}`);
  changePassword = (uuid, data) =>
    AuthAxios.put(`/admin-change-password/${uuid}`, data);
}

export default new AuthServices();
