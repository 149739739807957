import { createTheme, ThemeProvider } from "@mui/material";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Loading from "./Components/Loading";
import { AuthProvider } from "./Contexts/Auth.context";

import { Provider } from "react-redux";

import config from "./Utils/config";
import { store } from "./Redux/store";
import CustomizedSnackbars from "./Components/Snackbar";

function App() {
  // -- applying custom color theme -- \\
  const theme = createTheme({
    palette: {
      ...config.colors,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CustomizedSnackbars />
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <AuthProvider />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
