import { CardContent, Badge, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrubs from "../Components/Breadcrubs";
import CustomCard from "../Components/Card";
import { DashboardLayout } from "../Layouts";
import FlexBox from "../Components/FlexBox";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useAuth } from "../Hooks/useAuth";
// import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../Hooks/useSnackbar";
import MembershipServices from "../Services/Membership.services";
import { useSelector } from "react-redux";
import moment from "moment";

const GoldUserPurchasesScreen = () => {
  const [user] = useAuth();
  const chosenUser = useSelector((state) => state.chosenUser);
  const snackbar = useSnackbar();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 7,
    page: 0,
  });
  // const navigate = useNavigate();

  const [userPurchases, setUserPurchases] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: `${chosenUser.user.fullname} Purchases`,
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const tableHead = [
    {
      field: "id",
      headerName: "#",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Agreement",
      width: 250,
      headerClassName: "super-app-theme--header",
      field: "purchasedMembershipContractName",
    },
    {
      headerName: "Date & Time",
      field: "purchasedMembershipPurchasedDate",
      headerClassName: "super-app-theme--header",
      valueFormatter: (row) => moment(row.value).format("DD-MM-YYYY hh:mm A"),
      width: 250,
      renderCell: (row) => (
        <div>
          {moment(row.row?.purchasedMembershipPurchasedDate).format(
            "DD-MM-YYYY hh:mm A"
          )}
        </div>
      ),
    },
    {
      headerName: "QTY",
      field: "purchasedMembershipQty",
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Price",
      width: 364,
      field: "purchasedMembershipAmount",
      valueFormatter: (row) => row.value,
      headerClassName: "super-app-theme--header",
      renderCell: (row) => (
        <div>
          <p
            style={{
              textDecoration: "line-through",
              color: "lightgray",
              marginBottom: 0,
            }}
          >
            ${row.row?.purchasedMembershipOriginalAmount}
          </p>
          <p style={{ marginTop: 0 }}>${row.row?.purchasedMembershipAmount}</p>
        </div>
      ),
    },
  ];

  let unmount = false;
  const aboutController = new AbortController();
  const fetchUserPurchases = async () => {
    try {
      const { data } = await MembershipServices.getGoldUserPurchases(
        user?.uuid,
        chosenUser?.user?.email,
        aboutController.signal
      );
      data.map((ele, index) => (ele.id = index + 1));
      if (!unmount) setUserPurchases(data);
    } catch ({ response }) {
      snackbar({
        message: response.data.error || "Failed to fetching users",
      });
    }
    return () => {
      aboutController.abort();
      unmount = true;
    };
  };

  useEffect(() => {
    fetchUserPurchases();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout active={`/membership/user`}>
      <Breadcrubs
        title={chosenUser?.user?.fullName}
        parents={[
          { path: "/", label: "Dashboard" },
          { path: "/membership/users", label: "Gold Users" },
        ]}
      />
      <CustomCard>
        <CardContent>
          <FlexBox>
            <Typography
              variant="p"
              fontWeight={"regular"}
              color="primary.black"
              fontSize={18}
              display={"flex"}
              flexDirection={"column"}
            >
              <span>Name: {chosenUser?.user?.fullName}</span>
              <span>Email: {chosenUser?.user?.email}</span>
              <span>Phone: {chosenUser?.user?.phone}</span>
              <span>Code: {chosenUser?.user?.goldCode}</span>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "15%",
                }}
              >
                GoldMembership:{" "}
                <Badge
                  color={
                    chosenUser?.user?.goldStatus === "ACTIVE"
                      ? "success"
                      : "error"
                  }
                  badgeContent={
                    chosenUser?.user?.goldStatus === "ACTIVE"
                      ? "Active"
                      : "InActive"
                  }
                />
              </span>
              <span>
                Purchased:{" "}
                {moment(chosenUser?.user?.goldExpiryDate, "DD-MM-YYYY")
                  .subtract({ month: 6 })
                  .format("DD-MM-YYYY")}
              </span>
              <span>
                Expire:
                {chosenUser?.user?.goldExpiryDate}
              </span>
            </Typography>
          </FlexBox>
          <div style={{ height: "513px", marginTop: "3%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .super-app-theme--header": {
                  backgroundColor: "#C18E3C",
                },
              }}
              rows={userPurchases}
              columns={tableHead}
              slots={{ toolbar: CustomToolbar }}
              pageSizeOptions={[7]}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableDensitySelector
            />
          </div>
        </CardContent>
      </CustomCard>
    </DashboardLayout>
  );
};

export default GoldUserPurchasesScreen;
