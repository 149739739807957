import { Card } from "@mui/material";
import React from "react";
import config from "../Utils/config";

const CustomCard = ({ children, sx, ...rest }) => {
  return (
    <Card
      elevation={0}
      sx={{
        border: `1px solid ${config.colors.primary.border}`,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default CustomCard;
