import { Avatar, Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Images from "../../Common/assets";
import FlexBox from "../../Components/FlexBox";
import { useAuth } from "../../Hooks/useAuth";
import config from "../../Utils/config";

import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

const TopBar = ({ TOP_BAR_HEIGHT, logout }) => {
  const [user] = useAuth();

  return (
    <Stack
      justifyContent={"center"}
      sx={{
        position: "fixed",
        height: TOP_BAR_HEIGHT,
        width: "100%",
        zIndex: 10,
        bgcolor: "#fff",
        borderBottom: `.5px solid ${config.colors.primary.border}`,
      }}
    >
      <FlexBox>
        <FlexBox>
          <img src={Images.navbarLogo} width={100} alt="Logo" />
          <Stack sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography color="primary.dark" fontWeight={600}>
              {config.appName}
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              Admin Dashboard
            </Typography>
          </Stack>
        </FlexBox>
        <FlexBox sx={{ mx: { md: 2, xs: 1 } }}>
          <Stack
            flexDirection={"row"}
            alignItems="center"
            component={Link}
            to="/account"
          >
            <Typography
              variant={"subtitle1"}
              fontSize={16}
              color="text.dark"
              // sx={{ mx: 1 }}
              sx={{ display: { xs: "none", md: "flex" }, mx: 1 }}
            >
              {user?.fullName || "Admin"}
            </Typography>

            <Avatar />
          </Stack>
          <Button
            onClick={logout}
            startIcon={<LogoutIcon />}
            variant="outlined"
            size="small"
            color="secondary"
            sx={{ borderRadius: 30, mx: 2 }}
          >
            Logout
          </Button>
        </FlexBox>
      </FlexBox>
    </Stack>
  );
};

export default TopBar;
