import axios from "axios";

export const baseURL = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:8000/';
    default:
      return process.env.REACT_APP_URL;
  }
};

const AuthAxios = axios.create({
  baseURL: baseURL() + "api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 5000,
  timeoutErrorMessage: "Please check your connections",
});

export default AuthAxios;
