import { baseURL } from "../Services/AuthAxios";

const config = {
  appName: "92 Contracts",
  StorageBaseURL: baseURL(),
  colors: {
    primary: {
      main: "#C18E3C",
      dark: "#816333",
      light: "#D5B37D",
      border: "#cacaca",
    },
    secondary: {
      main: "#252525",
      dark: "#333",
      light: "#6D6D6D",
      border: "#eee",
    },
    text: {
      dark: "#333",
      light: "#fdfdfd",
      default: "#eee",
    },
  },
};

export default config;
