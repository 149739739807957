import { createSlice } from "@reduxjs/toolkit";

const initialState = { agreementPage: 0 };

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    agreementPage: (state, action) => {
      state.agreementPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { agreementPage } = pagesSlice.actions;

export default pagesSlice.reducer;
