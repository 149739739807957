import { CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrubs from "../Components/Breadcrubs";
import CustomCard from "../Components/Card";
import { DashboardLayout } from "../Layouts";
import FlexBox from "../Components/FlexBox";
import { useAuth } from "../Hooks/useAuth";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useSnackbar } from "../Hooks/useSnackbar";
import MembershipServices from "../Services/Membership.services";
import moment from "moment";

// icons
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import DeleteIcon from "@mui/icons-material/Delete";

const GoldPurchasesScreen = () => {
  const [user] = useAuth();
  const snackbar = useSnackbar();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "Gold Membership Purchases",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const [purchases, setPurchases] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 7,
    page: 0,
  });

  const tableHead = [
    {
      field: "id",
      headerName: "#",
      width: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Agreement",
      width: 250,
      headerClassName: "super-app-theme--header",
      field: "purchasedMembershipContractName",
    },
    {
      headerName: "Email",
      width: 250,
      headerClassName: "super-app-theme--header",
      field: "purchasedMembershipUserEmail",
    },
    {
      headerName: "Date & Time",
      field: "purchasedMembershipPurchasedDate",
      headerClassName: "super-app-theme--header",
      width: 250,
      valueFormatter: (row) => moment(row.value).format("DD-MM-YYYY hh:mm A"),
      renderCell: (row) => (
        <div>
          {moment(row.row?.purchasedMembershipPurchasedDate).format(
            "DD-MM-YYYY hh:mm A"
          )}
        </div>
      ),
    },
    {
      headerName: "QTY",
      field: "purchasedMembershipQty",
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Price",
      width: 214,
      field: "purchasedMembershipAmount",
      headerClassName: "super-app-theme--header",
      valueFormatter: (row) => row.value,
      renderCell: (row) => (
        <div>
          <p
            style={{
              textDecoration: "line-through",
              color: "lightgray",
              marginBottom: 0,
            }}
          >
            ${row.row?.purchasedMembershipOriginalAmount}
          </p>
          <p style={{ marginTop: 0 }}>${row.row?.purchasedMembershipAmount}</p>
        </div>
      ),
    },
  ];
  let unmount = false;
  const aboutController = new AbortController();
  const fetchUserPurchases = async () => {
    try {
      const { data } = await MembershipServices.getGoldPurchases(
        user?.uuid,
        aboutController.signal
      );
      data.map((ele, index) => (ele.id = index + 1));
      if (!unmount) setPurchases(data);
    } catch ({ response }) {
      snackbar({
        message: response.data.error || "Failed to fetching users",
      });
    }
    return () => {
      aboutController.abort();
      unmount = true;
    };
  };

  useEffect(() => {
    fetchUserPurchases();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout active={`/membership/purchases`}>
      <Breadcrubs
        title="Gold Purchases"
        parents={[{ path: "/", label: "Dashboard" }]}
      />
      <CustomCard>
        <CardContent>
          <FlexBox>
            <Typography
              variant="p"
              fontWeight={"h2"}
              color="primary.dark"
              fontSize={18}
              display={"flex"}
              flexDirection={"column"}
            >
              Gold Membership Purchases
            </Typography>
            <FlexBox>
              {/* <input placeholder="Search.." className="search" size="small" /> */}
            </FlexBox>
          </FlexBox>
          <div style={{ height: "513px", marginTop: "3%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .super-app-theme--header": {
                  backgroundColor: "#C18E3C",
                },
              }}
              rows={purchases}
              columns={tableHead}
              slots={{ toolbar: CustomToolbar }}
              pageSizeOptions={[7]}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableDensitySelector
            />
          </div>
        </CardContent>
      </CustomCard>
    </DashboardLayout>
  );
};

export default GoldPurchasesScreen;
