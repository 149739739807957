import {
  Button,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrubs from "../Components/Breadcrubs";
import CustomCard from "../Components/Card";
import { DashboardLayout } from "../Layouts";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import FlexBox from "../Components/FlexBox";
import { useAuth } from "../Hooks/useAuth";
import { useSnackbar } from "../Hooks/useSnackbar";
import MembershipServices from "../Services/Membership.services";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import * as XLSX from "xlsx";


const GoldCodesScreen = () => {
  const [user] = useAuth();
  const snackbar = useSnackbar();

  const [codes, setCodes] = useState([]);
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [modal, setModal] = useState(false);
  const [valid, setValid] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 7,
    page: 0,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "#ffffff",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "Membership Codes",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const generateUserData = async (code) => {
    const userData = await MembershipServices.getGoldCodePurchases(
      user?.uuid,
      code
    );
    if (userData.data.length === 0) {
      snackbar({
        message: "No Agreements Purchased By this code",
        type: "error",
      });
    } else {
      const wb = XLSX.utils.book_new();
      const exportedArray = [
        [
          "Name",
          "Email",
          "Phone Number",
          "Code",
          "Agreement Name",
          "Purchased On",
          "Amount",
        ],
      ];
      userData.data.forEach((item) =>
        exportedArray.push([
          item?.fullName,
          item?.email,
          item?.phone,
          item?.gold_code,
          item?.agr_name,
          item?.createdAt,
          item?.amount,
        ])
      );
      const sheet1 = XLSX.utils.aoa_to_sheet(exportedArray);
      XLSX?.utils.book_append_sheet(wb, sheet1, `${code}Purchases`);
      XLSX?.writeFile(wb, `${code}Purchases.csv`);
      snackbar({
        message: "Report Successfully Exported",
        type: "success",
      });
    }
  };

  const tableHead = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Title",
      width: 260,
      headerClassName: "super-app-theme--header",
      field: "membershipTitle",
    },
    {
      field: "membershipCode",
      headerName: "Code",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Expiry Date",
      field: "expiryDate",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (row) => (
        <div>{moment(row.row?.membershipExpiryDate).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      headerName: "Actions",
      field: "action",
      headerClassName: "super-app-theme--header",
      width: 280,
      renderCell: (row) => {
        return (
          <>
            <Button
              style={{ marginRight: "15%" }}
              onClick={() => generateUserData(row?.row?.membershipCode)}
            >
              Export User Data
            </Button>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => deleteHandler(row?.row?.membershipCode)}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const deleteHandler = async (membershipCode) => {
    if (!window.confirm("Do you want to delete this?")) return;
    try {
      const { data } = await MembershipServices.delete(
        membershipCode,
        user.uuid
      );
      fetchCodes();
      snackbar({
        message: data?.message || "Code Deleted",
        type: "success",
      });
    } catch ({ response }) {
      snackbar({
        message: response?.data?.message || "Error deleting category.",
      });
    }
  };

  const addCode = async () => {
    const expiryDateFormat = moment(expiryDate?.$d, "DD-MM-YYYY");
    const currentDate = moment();
    setValid(expiryDateFormat.isAfter(currentDate));
    if (valid) {
      const added = await MembershipServices.insert({
        title: title,
        code: code,
        expiryDate: expiryDateFormat.format(),
      });
      if (added.data.response === "SUCCESSFULLY CREATED") {
        closeModal();
        fetchCodes();
      }
    }
  };

  const closeModal = () => {
    setModal(false);
    setCode("");
    setExpiryDate("");
    setTitle("");
  };
  let unmount = false;
  const aboutController = new AbortController();
  const fetchCodes = async () => {
    try {
      const { data } = await MembershipServices.getAll(aboutController.signal);
      data.map((ele, index) => (ele.id = index + 1));
      if (!unmount) setCodes(data);
    } catch ({ response }) {
      snackbar({
        message: response.data.error || "Failed to fetching codes",
      });
    }
    return () => {
      aboutController.abort();
      unmount = true;
    };
  };

  useEffect(() => {
    fetchCodes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (code !== "" && title !== "" && expiryDate !== "") {
      setDisabled(false);
    }
    // eslint-disable-next-line
  }, [code, title, expiryDate]);

  return (
    <DashboardLayout active="/membership/codes">
      <Breadcrubs
        title={"Gold Codes"}
        parents={[{ path: "/", label: "Dashboard" }]}
      />
      <CustomCard>
        <CardContent>
          <FlexBox>
            <Typography
              variant="h2"
              fontWeight={"bold"}
              color="primary.dark"
              fontSize={28}
            >
              Gold Membership Codes
            </Typography>
            <FlexBox>
              <Button
                variant="contained"
                sx={{ borderRadius: 30, mx: 1 }}
                disableElevation
                startIcon={<AddIcon />}
                onClick={() => setModal(true)}
              >
                Add
              </Button>
            </FlexBox>
          </FlexBox>
          <div style={{ height: "513px", marginTop: "3%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .super-app-theme--header": {
                  backgroundColor: "#C18E3C",
                },
              }}
              rows={codes}
              columns={tableHead}
              slots={{
                toolbar: CustomToolbar,
              }}
              pageSizeOptions={[7]}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableDensitySelector
            />
          </div>
        </CardContent>
      </CustomCard>
      <Modal
        open={modal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
          alignItems={"start"}
        >
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            style={{ width: "60%" }}
          />
          <TextField
            id="outlined-basic"
            label="Code"
            variant="outlined"
            onChange={(e) => setCode(e.target.value)}
            placeholder="Code"
            style={{ width: "60%" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={expiryDate}
              onChange={setExpiryDate}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
          {!valid && (
            <InputLabel color="warning" error={true}>
              You must have a valid expiry Date
            </InputLabel>
          )}
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              style={{ color: disabled ? "" : "white" }}
              variant="contained"
              size="medium"
              disabled={disabled}
              onClick={addCode}
            >
              Add Code
            </Button>
          </div>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default GoldCodesScreen;
