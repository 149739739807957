import { createSlice } from "@reduxjs/toolkit";
const initialState = { value: { type: "error", message: "", open: false } };

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.value = {
        open: true,
        message: action.payload.message,
        type: action.payload.type || "error",
      };
    },
    closeSnackbar: (state) => {
      state.value = { ...state.value, message: "", open: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
