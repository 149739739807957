import { createSlice } from "@reduxjs/toolkit";

const initialState = { collapse: false, index: -1 };

export const collapseSlice = createSlice({
  name: "collapse",
  initialState,
  reducers: {
    changeCollapse: (state, action) => {
      state.collapse = action.payload.collapse;
      state.index = action.payload.index;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCollapse } = collapseSlice.actions;

export default collapseSlice.reducer;
