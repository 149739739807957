import AuthAxios from "./AuthAxios";

class MembershipService {
  getAll = (signal) => AuthAxios.get(`membership`, { signal });
  insert = (data) => AuthAxios.post(`membership`, data);
  addMembership = (uuid, data) =>
    AuthAxios.post(`/addMembership/${uuid}`, data);
  checkCode = (data) => AuthAxios.post(`checkMembershipCode`, data);
  getGoldUsers = (id, signal) => AuthAxios.get(`goldUsers/${id}`, signal);
  getGoldUserPurchases = (id, userId, signal) =>
    AuthAxios.get(`purchased-MembershipUserId/${id}&${userId}`, signal);
  getGoldPurchases = (id, signal) =>
    AuthAxios.get(`purchased-membership/${id}`, signal);
  getGoldCodePurchases = (id, code, signal) =>
    AuthAxios.get(`purchased-MembershipCode/${id}&${code}`, signal);
  update = (data, uuid) => AuthAxios.put(`categories/${uuid}`, data);
  delete = (code, uuid) =>
    AuthAxios.delete(`purchased-MembershipCodeDelete/${uuid}`, {
      params: {
        code,
      },
    });
}

export default new MembershipService();
