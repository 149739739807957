import { Button, CardContent, Typography, Badge } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrubs from "../Components/Breadcrubs";
import CustomCard from "../Components/Card";
import { DashboardLayout } from "../Layouts";
import FlexBox from "../Components/FlexBox";
import { useAuth } from "../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../Hooks/useSnackbar";
import MembershipServices from "../Services/Membership.services";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { chosenUser } from "../Redux/Slices/chosenUser.slice";
import moment from "moment";

// icons
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import DeleteIcon from "@mui/icons-material/Delete";

const GoldUsersScreen = () => {
  const [user] = useAuth();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 7,
    page: 0,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "Gold Membership Users",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const tableHead = [
    {
      field: "id",
      headerName: "#",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Title",
      width: 250,
      headerClassName: "super-app-theme--header",
      field: "fullName",
    },
    {
      field: "goldCode",
      headerName: "Code Used",
      width: 173,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Expiry Date",
      field: "goldExpiryDate",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (row) => (
        <div>{moment(row.row?.goldExpiryDate).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      headerName: "Account Verified",
      width: 140,
      field: "isVerified",
      headerClassName: "super-app-theme--header",
      alignHeader: "center",
      renderCell: (row) => {
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Badge
              color={row.row?.isVerified === 1 ? "success" : "error"}
              badgeContent={
                row.row?.isVerified === 1 ? "Verified" : "Not Verified"
              }
            />
          </div>
        );
      },
    },
    {
      headerName: "Gold Status",
      width: 100,
      field: "goldStatus",
      headerClassName: "super-app-theme--header",
      alignHeader: "center",
      renderCell: (row) => {
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Badge
              color={row.row?.goldStatus === "ACTIVE" ? "success" : "error"}
              badgeContent={
                row.row?.goldStatus === "ACTIVE" ? "Active" : "InActive"
              }
            />
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "",
      headerClassName: "super-app-theme--header",
      renderCell: (row) => {
        return (
          <Button
            onClick={() => {
              dispatch(chosenUser(row?.row));
              navigate("/membership/users/viewMore");
            }}
          >
            View More
          </Button>
        );
      },
    },
  ];

  let unmount = false;
  const aboutController = new AbortController();
  const fetchUsers = async () => {
    try {
      const { data } = await MembershipServices.getGoldUsers(
        user?.uuid,
        aboutController.signal
      );
      data.map((ele, index) => (ele.id = index + 1));
      if (!unmount) setUsers(data);
    } catch ({ response }) {
      snackbar({
        message: response.data.error || "Failed to fetching users",
      });
    }
    return () => {
      aboutController.abort();
      unmount = true;
    };
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout active="/membership/users">
      <Breadcrubs
        title={"Gold Users"}
        parents={[{ path: "/", label: "Dashboard" }]}
      />
      <CustomCard>
        <CardContent>
          <FlexBox>
            <Typography
              variant="h2"
              fontWeight={"bold"}
              color="primary.dark"
              fontSize={28}
            >
              Gold Membership Users
            </Typography>
            <FlexBox>
              {/* <input placeholder="Search.." className="search" size="small" /> */}
            </FlexBox>
          </FlexBox>
          <div style={{ height: "513px", marginTop: "3%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .super-app-theme--header": {
                  backgroundColor: "#C18E3C",
                },
              }}
              rows={users}
              columns={tableHead}
              slots={{ toolbar: CustomToolbar }}
              pageSizeOptions={[7]}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableDensitySelector
            />
          </div>
        </CardContent>
      </CustomCard>
    </DashboardLayout>
  );
};

export default GoldUsersScreen;
