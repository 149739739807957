import { Box, Container, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../Hooks/useAuth";
// import config from "../Utils/config";
import SideNavigation from "./Components/SideNavigation";
import TopBar from "./Components/TopBar";

const TOP_BAR_HEIGHT = `70px`;

const DashboardLayout = ({ children, active = false }) => {
  const matches = useMediaQuery("(min-width:600px)");
  const [sidebarWidth, setSidebarWidth] = useState(`250px`);

  const [user, authActions] = useAuth();

  // ### Logout Handler ## \\\
  const LogoutHandler = () => authActions.logout();

  useEffect(() => {
    setSidebarWidth(matches ? `250px` : `76px`);
  }, [matches]);
  return (
    <div>
      <TopBar logout={LogoutHandler} TOP_BAR_HEIGHT={TOP_BAR_HEIGHT} />
      <SideNavigation
        logout={LogoutHandler}
        WIDTH={sidebarWidth}
        active={active}
      />
      <Box sx={{ paddingLeft: sidebarWidth, paddingTop: TOP_BAR_HEIGHT }}>
        <Container>{children}</Container>
      </Box>
    </div>
  );
};

// const Footer = () => <Typography> {config.appName} </Typography>;

export default DashboardLayout;
