import React from "react";
import GoldCodesScreen from "../Screens/GoldCodes.screen";
import GoldPurchasesScreen from "../Screens/GoldPurchases.screen";
import GoldUsersScreen from "../Screens/GoldUsers.screen";
import GoldUserPurchasesScreen from "../Screens/GoldUsersPurchases.screen";

const Customers = React.lazy(() => import("../Screens/Customers.screen"));
const Dashboard = React.lazy(() => import("../Screens/Dashboard.screen"));
const Reports = React.lazy(() => import("../Screens/Reports.screen"));
const Users = React.lazy(() => import("../Screens/Users.screen"));

const Categories = React.lazy(() => import("../Screens/Categories.screen"));
const CreateCategories = React.lazy(() =>
  import("../Components/Categories/Create")
);
const EditCategories = React.lazy(() =>
  import("../Components/Categories/Edit")
);

const Agreements = React.lazy(() => import("../Screens/Agreements.screen"));
const AddAgreement = React.lazy(() => import("../Components/Agreements/Add"));
const EditAgreements = React.lazy(() =>
  import("../Components/Agreements/Edit")
);

const Login = React.lazy(() => import("../Screens/Login.screen"));
const Profile = React.lazy(() => import("../Screens/Profile.screen"));
const PurchaseAgreement = React.lazy(() =>
  import("../Screens/PurchaseAgreement.screen")
);

export const routes = [
  // ### Protected Routes ### \\
  {
    name: "Dashboard",
    path: "/",
    element: Dashboard,
    private: true,
  },
  {
    name: "Customers",
    path: "/customers",
    element: Customers,
    private: true,
  },
  {
    name: "Reports",
    path: "/reports",
    element: Reports,
    private: true,
  },
  {
    name: "Account",
    path: "/account",
    element: Profile,
    private: true,
  },
  {
    name: "Users",
    path: "/users",
    element: Users,
    private: true,
  },
  // agreements \\
  {
    name: "EditAgreements",
    path: "/agreements/edit",
    element: EditAgreements,
    private: true,
  },

  {
    name: "Agreements",
    path: "/agreements",
    element: Agreements,
    private: true,
  },

  {
    name: "CreateAgreement",
    path: "/agreement/create",
    element: AddAgreement,
    private: true,
  },

  {
    name: "PurchaseAgreement",
    path: "/purchase-agreement",
    element: PurchaseAgreement,
    private: true,
  },
  // categories \\
  {
    name: "Categories",
    path: "/categories",
    element: Categories,
    private: true,
  },
  {
    name: "CreateCategories",
    path: "/category/create",
    element: CreateCategories,
    private: true,
  },
  {
    name: "editCategories",
    path: "/category/edit",
    element: EditCategories,
    private: true,
  },
  {
    name: "goldMembershipCode",
    path: "/membership/codes",
    element: GoldCodesScreen,
    private: true,
  },
  {
    name: "goldMembershipCode",
    path: "/membership/users",
    element: GoldUsersScreen,
    private: true,
  },
  {
    name: "goldMembershipCode",
    path: "/membership/users/viewMore",
    element: GoldUserPurchasesScreen,
    private: true,
  },
  {
    name: "goldMembershipPurchases",
    path: "/membership/purchases",
    element: GoldPurchasesScreen,
    private: true,
  },
  // ### Public Routes ### \\
  {
    name: "Login",
    path: "/login",
    element: Login,
    private: false,
  },
];
