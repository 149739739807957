import { Breadcrumbs, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrubs({ title, parents }) {
  return (
    <Stack
      sx={{
        minHeight: "10vh",
      }}
      justifyContent="center"
    >
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          {parents.map((item, i) => (
            <Link
              underline="hover"
              style={{
                color: "#333",
              }}
              to={item.path}
              key={i}
            >
              {item.label}
            </Link>
          ))}

          <Typography color="text.primary">{title}</Typography>
        </Breadcrumbs>
      </Container>
    </Stack>
  );
}
