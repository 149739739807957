import LinearProgress from "@mui/material/LinearProgress";
import { Stack } from "@mui/system";
import React from "react";

const Loading = () => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100vh", width: "100vw" }}
    >
      Please wait
      <LinearProgress sx={{ width: 150, my: 2 }} />
    </Stack>
  );
};

export default Loading;
