import { Stack } from "@mui/system";
import React from "react";

const FlexBox = ({ children, ...rest }) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems="center"
      justifyContent={"space-between"}
      // sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
      flexWrap="wrap"
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default FlexBox;
