import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import { routes } from "./Routes";

export default function Navigator() {
  const [user] = useAuth();

  const PrivateRoutes = ({ children }) => {
    if (user?.email) {
      return <>{children}</>;
    } else return <Navigate to={"/login"} replace={true} />;
  };

  return (
    <Routes>
      {routes.map((r, i) =>
        r.private ? (
          <Route
            key={i}
            exact
            path={r.path}
            element={
              <PrivateRoutes routeRole={r.private}>
                <r.element />
              </PrivateRoutes>
            }
          />
        ) : (
          <Route key={i} exact path={r.path} element={<r.element />} />
        )
      )}
    </Routes>
  );
}
