import React, { useEffect, useState } from "react";
import { Box, Collapse, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import CategoryIcon from "@mui/icons-material/Category";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import AssessmentIcon from "@mui/icons-material/Assessment";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { changeCollapse } from "../../Redux/Slices/subMenuCollapse.slice";

const RenderCard = ({ Icon, index, name, path, active, submenu, ...rest }) => {
  const dispatch = useDispatch();
  const collapseState = useSelector((state) => state.collapse);
  const [open, setOpen] = useState(collapseState.collapse);
  const [collapseIndex, setCollapseIndex] = useState(collapseState.index);

  const checkIsActive = () => {
    if (submenu) {
      return !!path.filter((p) => p === active).length;
    } else return path === active;
  };

  return (
    <>
      <Stack
        component={Link}
        to={path}
        flexDirection={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          py: 1,
          px: 2,
          my: 0.5,
          "&:hover": {
            bgcolor: checkIsActive() ? null : "secondary.light",
          },
          bgcolor: checkIsActive() ? "primary.main" : null,
          cursor: "pointer",
        }}
        onClick={() => {
          if (submenu) {
            dispatch(changeCollapse({ collapse: true, index: index }));
            setOpen(index === collapseIndex ? (prev) => !prev : true);
            setCollapseIndex(index);
          }
        }}
        {...rest}
      >
        <Stack flexDirection={"row"}>
          <Icon sx={{ color: "text.light" }} />
          <Typography
            variant="subtitle1"
            color={"text.light"}
            sx={{ mx: 2, display: { xs: "none", sm: "block" } }}
            fontSize={13}
          >
            {name}
          </Typography>
        </Stack>
        {submenu && (
          <>
            {open && collapseIndex === index ? (
              <ExpandLess sx={{ color: "text.light" }} />
            ) : (
              <ExpandMore sx={{ color: "text.light" }} />
            )}
          </>
        )}
      </Stack>
      {submenu?.length && (
        <Collapse
          in={collapseIndex === index && open === true}
          timeout="auto"
          unmountOnExit
        >
          {submenu.map((menu, i) => (
            <Stack
              flexDirection={"row"}
              key={i}
              component={Link}
              sx={{
                py: 1,
                px: 4,
                my: 0.5,
                "&:hover": {
                  bgcolor: menu.path === active ? null : "primary.light",
                },
                bgcolor: menu.path === active ? "primary.dark" : null,
                cursor: "pointer",
              }}
              to={menu.path}
            >
              <menu.Icon sx={{ color: "text.light" }} />
              <Typography
                variant="subtitle1"
                color={"text.light"}
                sx={{ mx: 2, display: { xs: "none", sm: "block" } }}
                fontSize={13}
              >
                {menu.name}
              </Typography>
            </Stack>
          ))}
        </Collapse>
      )}
    </>
  );
};
const SideNavigation = ({ active, WIDTH, logout }) => {
  return (
    <Stack
      sx={{
        position: "fixed",
        width: { sm: WIDTH },
        bgcolor: "secondary.main",
        height: "100%",
        top: 70,
      }}
    >
      <Box sx={{ my: 3 }}>
        {sideBarMenus.map((ele, i) => (
          <RenderCard active={active} key={i} index={i} {...ele} />
        ))}
        <Divider sx={{ bgcolor: "#eee" }} />
        {/* <RenderCard name="Profile" active={active} Icon={AccountCircleIcon} /> */}
        <RenderCard
          name="Logout"
          active={active}
          onClick={logout}
          Icon={LogoutIcon}
        />
      </Box>
    </Stack>
  );
};

export const sideBarMenus = [
  {
    path: "/",
    name: "Dashboard",
    Icon: DashboardIcon,
  },
  {
    path: "/customers",
    name: "Customers",
    Icon: PeopleIcon,
  },
  {
    path: ["/agreements", "/agreement/create"],
    name: "Agreements",
    Icon: ArticleIcon,
    submenu: [
      { path: "/agreement/create", name: "Add agreement", Icon: AddIcon },
      { path: "/agreements", name: "List of agreements", Icon: ViewListIcon },
    ],
  },
  {
    path: ["/categories", "/category/create"],
    name: "Categories",
    Icon: CategoryIcon,
    submenu: [
      {
        path: "/category/create",
        name: "Add category",
        Icon: AddIcon,
      },
      {
        path: "/categories",
        name: "List of category",
        Icon: ViewListIcon,
      },
    ],
  },
  {
    path: ["/membership/users", "/membership/codes", "/membership/purchases"],
    name: "Gold Membership",
    Icon: CardMembershipIcon,
    submenu: [
      {
        path: "/membership/users",
        name: "Users",
        Icon: PeopleIcon,
      },
      {
        path: "/membership/codes",
        name: "Gold Codes",
        Icon: VpnKeyIcon,
      },
      {
        path: "/membership/purchases",
        name: "Gold Purchases",
        Icon: ShoppingCartIcon,
      },
    ],
  },
  {
    path: "/purchase-agreement",
    name: "Purchase agreement",
    Icon: PaymentIcon,
  },
  {
    path: "/reports",
    name: "Sales Reports",
    Icon: AssessmentIcon,
  },
  {
    path: "/account",
    name: "Account",
    Icon: AccountCircleIcon,
  },
];

export default SideNavigation;
